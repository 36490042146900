@import "../../app.scss";
.about {
  height: 100vh;
  background-color: black;
  @include mobile {
    height: 150vh;
  }
  .aboutContainer {
    margin: 0 15%;
    display: flex;
    flex-direction: column;
    flex-wrap: wraps;
    @include tablet {
      margin: 0 5%;
    }
    .aboutCompany {
      display: flex;
      flex-wrap: wrap;
      height: 40vh;
      margin: 20px;
      @include tablet {
        margin: 5% 10px;
      }
      .aboutCompanyInfo {
        margin: 0 20px;
        min-width: 300px;
        flex: 1;
        text-align: center;
        h2 {
          color: cyan;
          font-size: 30px;
          margin: 20px 0;
        }
        p {
          color: gray;
          font-size: 20px;
          margin: 20px 20px;
          @include tablet {
            margin: 10px;
            font-size: 16px;
          }
        }
      }
      .aboutCompanyImg {
        flex: 1;
        width: 50%;
        margin: 0 20px;
        @include mobile {
          display: none;
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
    .aboutCeo {
      display: flex;
      flex-wrap: wrap;
      margin-top: 3vh;
      @include mobile {
        margin-top: 10vh;
      }
      .ceo {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 50vh;
        @include tablet {
          margin-top: -10vh;
        }
        .CeoImg {
          height: 100%;
          object-fit: contain;
          min-width: 300px;
          @include mobile {
            padding-top: 40px;
          }
        }
        .ceoName {
          margin: 0 auto;
          text-align: center;
          color: white;
          h3 {
            color: cyan;
          }
        }
      }
      .desc {
        flex: 1;
        min-width: 300px;
        margin-top: 10%;
        text-align: center;
        @include mobile {
          margin-top: 10vh;
        }
        .h2 {
          margin-top: 20px;
        }
        h2 {
          color: cyan;
          font-size: 30px;
        }
        p {
          font-size: 20px;
          margin: 20px 30px;
          color: gray;
          @include mobile {
            font-size: 18px;
          }
        }
      }
    }
  }
}
