@mixin tablet {
  @media (max-width: 820px) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}
.App {
  position: relative;
  overflow-x: hidden;
}
@media (min-width: 1200px) {
  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: black;
  }

  ::-webkit-scrollbar-thumb {
    background: cyan;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
