.serviceCard {
  position: relative;
  height: 30vh;
  min-width: 300px;
  margin-top: 10px;
  padding-top: 10px;
  overflow: hidden;
  font-weight: 200;
  text-align: center;
  width: 20vw;
  background-color: white;
  color: black;
  margin: 0 10px;
  border-radius: 10px;
  -webkit-box-shadow: -8px 20px 44px 10px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: -8px 20px 44px 10px rgba(0, 0, 0, 0.32);
  box-shadow: -8px 20px 44px 10px rgba(0, 0, 0, 0.32);
  .ServiceTitle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3%;
    top: 0;
    font-size: 24px;
    color: darkcyan;
    width: 100%;
    height: 30%;
    background-color: white;
    z-index: 1;
  }
  .TitleContainer {
    height: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ServiceDesc {
    font-weight: 400;
    overflow-y: hidden;
    transition: all 15s ease;
    height: 100%;
    z-index: -1;
    p {
      padding: 0 5px;
    }
    &:hover {
      z-index: -1;
      transform: translateY(-70%);
    }
  }
}
.services {
  width: 100vw;
  color: white;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 255, 255, 1) 75%,
    rgba(255, 255, 255, 1) 89%
  );
  .services-container {
    margin: 0 15%;
    h2 {
      padding-top: 50px;
      text-align: center;
      font-size: 50px;
      border-bottom: 5px solid cyan;
    }
    .service__body {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      .service {
        margin: 20px 1vw;
      }
    }
  }
}
