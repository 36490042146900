@import "../../app.scss";

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: white;
  z-index: 1;
  @include tablet {
    position: absolute;
  }
  .navleft {
    margin: 20px 5%;
    img {
      height: 50px;
      object-fit: contain;
    }
  }
  .navRight {
    display: flex;
    margin: 0 5%;
    text-decoration: none;
    color: black;
    @include tablet {
      font-size: 14px;
    }
    @include mobile {
      display: none;
    }
    .link {
      margin: 0 10px;
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      &:hover {
        color: aqua;
        font-size: 1rem;
        text-decoration: underline;
      }
    }
  }
  .mobileMenu{
    color: black;
    display: flex;
    flex-direction: column;
    margin-top: 35%;
    border-radius: 15px;
    margin-right: 5%;
    padding: 15px 30px;
    height: 150px;
    z-index: 100;
    background-color: rgb(49, 192, 244);
    .link {
      margin: 0 10px;
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }
  }
}
.menuBtn{
  display: none;
  @include mobile{
    display: block;
      color: black;
      z-index: 100;
      margin-right:30px;
   
  }
}
.scrollUpArrow {
  z-index: 999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  bottom: 5%;
  right: 5%;
  width: 40px;
  height: 40px;
  background-color: cyan;
  .link {
    color: inherit;
    text-decoration: none;
  }
  .upArrowImg {
    height: 20px;
    width: fit-content;
  }
}
.none {
  display: none;
}
