.footer {
  background-color: black;
  color: darkgray;
  height: 10vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  p {
    margin: 0 5%;
  }
}
