@import "../../app.scss";
.c {
  z-index: 4;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0, 255, 255);
  background: linear-gradient(
    356deg,
    rgba(0, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  @include mobile {
    height: 120vh;
  }

  .c-wrapper {
    height: 100%;
    width: 98vw;
    display: flex;
    flex-wrap: wrap;
    @include tablet {
    }
  }

  .c-left {
    flex: 1;
    padding: 50px;
    flex-wrap: wrap;
    @include tablet {
      display: flex;
      width: 100vw;
      padding-bottom: 0;
      justify-content: space-between;
    }
  }

  .c-right {
    flex: 1;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 50%;
    @include tablet {
      padding-top: 0;
      margin-top: -10vh;
    }
  }

  .c-title {
    font-size: 60px;
    width: 80%;
  }

  @include mobile {
    .c-title {
      font-size: 24px;
    }
    .c-info {
      width: 100vw;
    }
  }

  .c-info-item {
    display: flex;
    align-items: center;
    margin: 50px 0;
    font-weight: 300;
    width: 70%;
    @include mobile {
      margin: 10px 0;
    }
  }

  .c-icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }

  .link {
    text-decoration: none;
    color: inherit;
  }

  .c-desc {
    font-weight: 200;
    font-size: 18px;
    width: 100%;
    text-align: left;
    @include mobile {
      display: none;
    }
  }

  form {
    margin-top: 20px;
  }

  input {
    width: 50%;
    height: 50px;
    border: none;
    border-bottom: 1px solid black;
    margin: 10px 0;
    font-size: 14px;
    padding-left: 10px;
    @include mobile {
      width: 100%;
    }
  }

  textarea {
    width: 50%;
    margin: 10px 0;
    font-size: 14px;
    padding-left: 10px;
    @include mobile {
      width: 100%;
    }
  }
  button {
    border: none;
    padding: 15px;
    background-color: #59b256;
    color: white;
    font-weight: 500;
    cursor: pointer;
  }
}
