@import "../../app.scss";
.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: rgb(0, 0, 0);
  overflow: hidden;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 255, 255, 1) 75%,
    rgba(255, 255, 255, 1) 89%
  );
  @include tablet {
    width: 100vw;
  }
  @include mobile {
    width: 100vw;
  }
  color: white;
  .home-quote {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    @include tablet {
      margin-top: 20vh;
      justify-content: center;
    }

    hr {
      width: 5px;
      height: 50%;
      background-color: cyan;
      border: none;
      margin: 0 20px;
      @include mobile {
        display: none;
      }
      @include tablet {
        display: none;
      }
    }
    .quote-container {
      width: 40%;
      min-width: 300px;
      font-size: 1rem;
      @include tablet {
        width: 50%;
        font-size: 13px;
        justify-content: left;
      }
      @include mobile {
        width: 50%;
        font-size: 11px;
      }
      .quote {
        text-align: center;
        width: 100%;
      }
    }
    .videoContainer {
      height: 40vh;
      overflow: hidden;
      object-fit: cover;
      video {
        height: 100%;
      }
      @include mobile {
        video {
          width: 290px;
          height: fit-content;
          margin: 5vh 0 0 0;
        }
      }
    }
  }
}
